

























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import HtmlSanitizer from "../shared/HtmlSanitizer";

@Component
export default class HelpText extends Vue {
  @Prop({ default: "" }) public userInput!: string;
  @Prop({ default: [] }) public alsoSearchedFor!: string[];
  @Prop({ default: false }) public hasError!: boolean;

  public get sanitizedInput() {
    const sanitized = HtmlSanitizer.sanitize(this.userInput);

    return sanitized;
  }

  public get terms() {
    return this.alsoSearchedFor; //.filter((t, i) => i < 2)
  }

  @Prop({ default: 0 }) total!: number;

  @Prop({ default: false }) isLoading!: boolean;
}
