

















































import { Component, Prop, Vue } from "vue-property-decorator";
import EventBus from "../shared/EventBus";
import { Route } from "vue-router";
import _ from "lodash";
import config from "../config";
import HtmlSanitizer from "../shared/HtmlSanitizer";

@Component
export default class SearchBar extends Vue {
  public input: string = "";
  public $route!: Route;
  public isSearchBarOpen: boolean = false;
  public autocompleteResults: string[] = [];
  public selectedIndex: number | null = null;

  constructor() {
    super();
    this.search = this.search.bind(this);
    this.autocomplete = _.debounce(this.autocomplete.bind(this), 200, {
      leading: true,
      trailing: true
    }); //respond quickly, and make sure the last typed letter is included in the last autocomplete recommendations
  }

  created() {
    if (this.$route.query.searchTerm) {
      this.input = this.$route.query.searchTerm.toString();
      this.isSearchBarOpen = true;
    }
  }

  mounted() {
    if (this.isSearchBarOpen) {
      this.openSearchBar();
      document.addEventListener('click', this.closeAutoComplete);
    }
  }

  closeAutoComplete(event: any)
  {
    if(event.target != document.querySelector(".hbs__input"))
       this.onEsc();
  }

  onSearchActivateClick() {
    if (this.isSearchBarOpen) {
      this.search();
    } else {
      this.openSearchBar();
      this.focusSearchInput();
    }
  }

  onSearchCloseClick() {
    this.closeSearchBar();
  }

  onDownArrow() {
    if (!this.autocompleteResults.length) return;
    this.selectedIndex =
      this.selectedIndex == null ? 0 : this.selectedIndex + 1;
  }

  onUpArrow() {
    if (this.selectedIndex) this.selectedIndex--;
  }

  onEsc() {
    this.autocompleteResults = [];
  }

  onAutocompleteClick(index: number) {
    this.selectedIndex = index;
    this.search();
  }

  onSearchFocus() {
    const searchInputEl = <HTMLInputElement>this.$refs.search;
    searchInputEl.setSelectionRange(0, searchInputEl.value.length);
  }

  onAutocompleteMouseout() {
    this.selectedIndex = null;
  }

  private openSearchBar() {
    var elems = document.querySelectorAll(".header__bottom"); // this is outsize the control of VueJS, so update it in plain javascript

    [].forEach.call(elems, function(el: Element) {
      el.classList.remove("search-close");
      el.classList.add("search-open");
    });

    this.isSearchBarOpen = true;
  }

  private closeSearchBar(){
    var elems = document.querySelectorAll(".header__bottom"); // this is outsize the control of VueJS, so update it in plain javascript

    [].forEach.call(elems, function(el: Element) {
      el.classList.remove("search-open");
      el.classList.add("search-close");
    });

    this.isSearchBarOpen = false;
    
    this.clearSearchInput();
  }

  private clearSearchInput(){
    this.input = '';
    this.autocompleteResults = [];
    
  }

  private focusSearchInput() {
    const searchInputEl = <HTMLInputElement>this.$refs.search;
    searchInputEl.focus();
  }

  search() {
    if (this.selectedIndex != null) {
      this.input = this.autocompleteResults[this.selectedIndex];
    }
    this.autocompleteResults = [];
    this.selectedIndex = null;
    EventBus.$emit("search", this.input);
  }

  autocomplete(event: any) {
    if (!this.input) return;
    if (event.key == "Enter") return;
    if (event.key == "ArrowUp") return;
    if (event.key == "ArrowDown") return;
    if (event.key == "Escape") return;

    this.selectedIndex = null;

    this.$http
      .get<string[]>(
        `${config.apiAuthority}/api/search/autocomplete` +
          `?currentInput=${encodeURIComponent(
            HtmlSanitizer.sanitize(this.input)
          )}`
      )
      .then(res => {
        this.autocompleteResults = res.data;
      });
  }
}
