











import {Component, Vue, Prop} from "vue-property-decorator";
import {ContributorSelection} from './BlogPostResults';

@Component
export default class ContributorFilters extends Vue {
    @Prop({default: []}) contributors!: ContributorSelection[]; 
    
    onContributorClick(id: string){
        this.$emit('select', id)
    }
}
