

















































import { Component, Vue, Prop } from "vue-property-decorator";
import { Selection as SelectionModel } from './BlogPostResults'

@Component
export default class CategoryFilters extends Vue {
    @Prop({ default: () => [] }) categories!: SelectionModel[];
    @Prop({ default: '' }) selected!: string;
    @Prop({ default: '' }) selecteds!: string;
    @Prop({ default: false }) isActive!: boolean;
    public sel: string = "";
    public hidden: boolean = true;
    onCategoryClick(id: string, urlName: string) {
        this.$emit("categorySelect", id, urlName);
    }

    toggle(cat: SelectionModel){
        cat.isActive = !cat.isActive;
        if(this.selecteds == cat.Title){
            this.selecteds = "";
        }
        else{
            this.selecteds = cat.Title
        }
        console.log(this.selecteds);
    }
    isItActive(cat: SelectionModel){
        console.log("test " + cat.Title == this.selecteds);
        return cat.Title == this.selecteds;

    }
}
