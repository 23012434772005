import DOMPurify from 'dompurify';

class HtmlSanitizer {
    constructor() {
        DOMPurify.setConfig({
            ALLOWED_TAGS: ['a', 'p', 'br', 'hr', 'abbr', 'address', 'b', 'blockquote', 'cite', 'code', 'del',
                'dfn', 'em', 'i', 'kbd', 'mark', 'meter', 'pre', 'progress', 'q', 'samp', 'small', 'strong',
                'sub', 'sup', 'time', 'u', 'var', 'ul', 'ol', 'li', 'dl', 'dt', 'dt', 'div', 'span', 'style',
            ],
            ALLOWED_ATTR: ['cite', 'class', 'cols', 'colspan', 'datetime', 'dir', 'headers', 'high', 'href', 'lang',
                'low', 'max', 'min', 'optimum', 'reversed', 'rowspan', 'scope', 'start', 'style', 'target', 'type',
                'value',
            ],
            SAFE_FOR_TEMPLATES: true,
            IN_PLACE: false,
        });
    }

    sanitize(input: string): string {
        return DOMPurify.sanitize(input);
    }
}

const sanitizer = new HtmlSanitizer();

export default sanitizer;