



























import { Component, Vue } from "vue-property-decorator";
import { Route } from "vue-router";

import FAQListing from "./FAQListing.vue";
import Paging from "../shared/Paging.vue";
import { FAQResults, FAQResult, Selection } from "./FAQResult";
import config from "../config";
import FAQFilters from "./FAQFilters.vue";

const EmptyGuid: string = "00000000-0000-0000-0000-000000000000";

@Component({
  components: {
    FAQListing,
    Paging,
    FAQFilters
  }
})
export default class FAQApp extends Vue {
  public results: FAQResult[] = [];
  public page: number = 1;
  public take: number = 6;
  public total: number = 0;
  public $route!: Route;
  public isLoading: boolean = false;
  public categories: Selection[] = [];
  public selectedCategoryId: string = "";
  public selectedCategoryUrlName: string = "";
  public hideFilters = true;


  onSetPage(page: number) {
    this.page = page;
    this.updateResults();
  }

  public created() {
    if (this.$route.hash) {
      this.selectedCategoryUrlName = this.$route.hash.toString().replace('#', '');
    }
    this.updateResults();
  }

  onCategoryItemSelected(id: string, urlName: string) {
    this.clearSelections();
    this.selectedCategoryId = id;
    var hash = urlName == "" ? window.location.pathname : '#' + urlName;
    history.pushState(null, '', hash);
    this.updateResults();
  }

  private clearSelections() {
    this.selectedCategoryId = "";
    this.page = 1;
  }

private updateResults() {

    this.isLoading = true;
    this.$http
      .get<FAQResults>(
        `${config.apiAuthority}/api/faq` +
          `?page=${this.page}` +
          `&take=${this.take}` +
          `&category=${this.selectedCategoryId == EmptyGuid ? "" : this.selectedCategoryId}` + 
          `&categoryUrlName=${this.selectedCategoryUrlName}`
      )
      .then(res => {
        this.isLoading = false;
        this.results = res.data.Results;
        this.total = res.data.Total;
        this.selectedCategoryId = res.data.SelectedCategoryId
        this.categories = res.data.Categories;
        this.categories.push(<Selection>{
          Title: "Show All",
          Id: EmptyGuid,
          Count: res.data.UnfilteredTotal,
          CategoryUrlName: ""
        });

        //window.scrollTo({ top: 0 });
      });
  }
}
