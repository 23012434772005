





































import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";

@Component
export default class Paging extends Vue {
  @Prop({ default: 1 }) page!: number;
  @Prop({ default: 0 }) total!: number;
  @Prop({ default: 1 }) take!: number;

  private firstVisiblePage: number = 1;

  get isPrevDisabled() {
    return this.page <= 1;
  }

  get isNextDisabled() {
    return this.page >= this.lastPage;
  }

  get lastPage() {
    const lastPage = Math.ceil(this.total / this.take);
    //console.log(`${lastPage} is last page`);
    return lastPage;
  }

  get showFirstElipses() {
    return this.firstVisiblePage > 2;
  }

  get showLastElipses() {
    return this.firstVisiblePage + this.visiblePageCount < this.lastPage;
  }

  get visiblePageCount() {
    return 3; // this may depend on the breakpoint
  }

  get visiblePages(): number[] {
    const results = _.range(
      Math.max(this.firstVisiblePage, 1),
      Math.min(this.firstVisiblePage + this.visiblePageCount, this.lastPage)
    ).filter(item => item > 1);

    //results.forEach(item => console.log(`${item} included in paging`));

    return results;
  }

  onFirstElipsesClick() {
    this.firstVisiblePage =
      this.firstVisiblePage > this.visiblePageCount
        ? this.firstVisiblePage - this.visiblePageCount
        : 1;
  }
  onLastElipsesClick() {
    this.firstVisiblePage =
      this.firstVisiblePage + this.visiblePageCount < this.lastPage
        ? this.firstVisiblePage + this.visiblePageCount
        : this.lastPage - this.visiblePageCount;
  }

  private setPage(page: number) {
    this.$emit("setPage", page);
  }

  onPrevClick() {
    if (this.isPrevDisabled) return;

    const prevPage = this.page - 1;

    this.setPage(prevPage);

    if (!this.visiblePages.includes(prevPage))
      this.firstVisiblePage = Math.min(
        prevPage,
        this.lastPage - this.visiblePageCount
      );

    this.resetAccordion();
  }

  onNumClick(page: number) {
    this.setPage(page);

    if (page == this.lastPage)
      this.firstVisiblePage = this.lastPage - this.visiblePageCount + 1;
    else if (page == 1) this.firstVisiblePage = 1;

    this.resetAccordion();
  }

  onNextClick() {
    if (this.isNextDisabled) return;
    const nextPage = this.page + 1;

    this.setPage(nextPage);

    if (!this.visiblePages.includes(nextPage))
      this.firstVisiblePage = Math.min(
        nextPage,
        this.lastPage - this.visiblePageCount
          );

    this.resetAccordion();
  }

  resetAccordion() {
    var openAccordion = document.getElementsByClassName("show");
    if (openAccordion.length > 0) {
        openAccordion[0].classList.remove("show");
    }
  }
}
