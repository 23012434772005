


















import { Component, Vue, Prop } from "vue-property-decorator";
import { ContributorDetails } from "./BlogPostResults";

@Component
export default class ContributorInfo extends Vue {
  @Prop({ default: null }) details!: ContributorDetails | null;

  onClearContributorClick(){
      this.$emit('clear')
  }
}
