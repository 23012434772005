export interface IConfig {
    searchResultsPath: string;
    apiAuthority: string;
}

const dev_config = <IConfig>{
    searchResultsPath: 'dynamic-search-results.html',
    apiAuthority: 'https://localhost:44372'
};

const prod_config = <IConfig>{
    searchResultsPath: 'search-results',
    apiAuthority: ''
};

const config = process.env.NODE_ENV === "development" 
    ? dev_config
    : prod_config;

export default config;