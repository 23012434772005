





















































import { Component, Vue } from "vue-property-decorator";

import config from "../config";
import CategoryFilters from "./CategoryFilters.vue";
import ContributorFilters from "./ContributorFilters.vue";
import Tags from "./Tags.vue";
import Paging from "../shared/Paging.vue";
import SortOptions from "./SortOptions.vue";
import ContributorInfo from "./ContributorInfo.vue";
import CategoryFiltersMobile from "./CategoryFiltersMobile.vue";
import BlogPost from "./BlogPost.vue";
import {
  BlogPost as BlogPostModel,
  BlogPostResults,
  Selection,
  ContributorSelection,
  ContributorDetails
} from "./BlogPostResults";
const EmptyGuid: string = "00000000-0000-0000-0000-000000000000";

@Component({
  components: {
    Paging,
    CategoryFilters,
    CategoryFiltersMobile,
    Tags,
    ContributorFilters,
    ContributorInfo,
    SortOptions,
    BlogPost
  }
})
export default class BlogListingApp extends Vue {
  public posts: BlogPostModel[] = [];
  public isLoading: boolean = true;

  public take: number = 7;
  public total: number = 0;
  public page: number = 1;
  public categories: Selection[] = [];
  public mobileCategories: Selection[] = [];
  public tags: Selection[] = [];
  public contributors: ContributorSelection[] = [];
  public selectedCategoryId: string = EmptyGuid;
  public selectedCategoryUrlName: string = "";
  public selectedTagId: string = "";
  public selectedContributorId: string = "";
  public contributorDetails: ContributorDetails | null = null;

  created() {
    console.log(this.$route.query.contributor);
    if (this.$route.hash) {
      this.selectedCategoryUrlName = this.$route.hash.toString().replace('#', '');
    }
    if (this.$route.query && this.$route.query.contributor){
      this.selectedContributorId = this.$route.query.contributor.toString();
    }
    this.updateResults();
  }

  onSetPage(page: number) {
    this.page = page;
    this.updateResults();
  }

  onCategorySelect(id: string, urlName: string) {
    this.clearSelections();
    this.selectedCategoryId = id;
    var hash = urlName == "" ? window.location.pathname : '#' + urlName;
    history.pushState(null, '', hash);
    this.updateResults();
  }

  onTagSelect(id: string) {
    this.clearSelections();
    this.selectedTagId = id;
    this.updateResults();
  }

  onClearContributor() {
    this.clearSelections();
    this.updateResults();
  }

  onContributorSelect(id: string) {
    this.clearSelections();
    this.selectedContributorId = id;
    this.updateResults();
  }

  private clearSelections() {
    this.selectedTagId = "";
    this.selectedCategoryId = "";
    this.selectedContributorId = "";
    this.page = 1;
  }

  private updateResults() {
    this.isLoading = true;
    this.$http
      .get<BlogPostResults>(
        `${config.apiAuthority}/api/blog/posts` +
          `?take=${this.take}` +
          `&page=${this.page}` +
          `&category=${this.selectedCategoryId == EmptyGuid ? "" : this.selectedCategoryId}` + 
          `&categoryUrlName=${this.selectedCategoryUrlName}` +
          `&tag=${this.selectedTagId}` +
          `&contributor=${this.selectedContributorId}` + 
          `&blogSource=Blog`
      )
      .then(res => {
        this.isLoading = false;
        this.posts = res.data.Results;
        this.total = res.data.Total;
        this.categories = res.data.Categories;
        this.mobileCategories = res.data.MobileCategories;
        this.selectedCategoryId = res.data.SelectedCategoryId;
        this.contributors = res.data.Contributors;
        this.contributorDetails = res.data.ContributorDetails;
        this.tags = res.data.Tags;

        window.scrollTo({ top: 0 });
      });
  }
}
