













import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class SearchFilters extends Vue {
    @Prop({default: []}) selections!: Selection[];
    @Prop({default: true}) hiddenToMobile!: boolean;
    @Prop({default: ''}) selected!: string;
    
    onClick(id: string){
        this.$emit('itemSelected', id);
    }
}
