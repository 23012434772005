













import {Component, Prop, Vue} from "vue-property-decorator";
import {Selection as SelectionModel} from "./FAQResult"
@Component
export default class FAQFilters extends Vue {
    @Prop({default: []}) selections!: SelectionModel[];
    @Prop({default: true}) hiddenToMobile!: boolean;
    @Prop({default: ''}) selected!: string;
    
    onClick(id: string, urlName: string){
        this.$emit('itemSelected', id, urlName);
    }
}
