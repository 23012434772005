












import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component
export default class CategoryFiltersMobile extends Vue {
    @Prop({default: []}) mobileCategories!: Selection[];
    @Prop({default: ''}) selected!: string;

    mySelected: string = '';

    @Watch('selected')
    selectedChanged(value: string) {
        this.mySelected = value;
    }

    onSelectChange() {
        this.$emit("categorySelect", this.mySelected)
    }
}
