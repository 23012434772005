















import {Component, Prop, Vue} from "vue-property-decorator";
import {FAQResult} from "./FAQResult";

@Component
export default class FAQListing extends Vue {
    @Prop({default: []}) results!: FAQResult[];

    
}
