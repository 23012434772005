


















import { Component, Prop, Vue } from "vue-property-decorator";
import { BlogPost as BlogPostModel } from "@/blog/BlogPostResults";

@Component
export default class BlogPost extends Vue {
  @Prop({}) post!: BlogPostModel;

  get imageStyles() {
    return { backgroundImage: "url('" + this.post.ImageUrl + "')" };
  }
}
