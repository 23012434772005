



































import { Component, Vue } from "vue-property-decorator";
import { Route } from "vue-router";

import SearchResultsListing from "./SearchResultsListing.vue";
import SearchResultsModel, {
  SearchResult,
  SelectionModel
} from "./SearchResults";
import Paging from "../shared/Paging.vue";
import EventBus from "../shared/EventBus";
import config from "../config";
import HelpText from "@/search/HelpText.vue";
import HtmlSanitizer from "@/shared/HtmlSanitizer";
import SearchFilters from "./SearchFilters.vue";

@Component({
  components: {
    SearchResultsListing,
    Paging,
    HelpText,
    SearchFilters
  }
})
export default class SearchResultsApp extends Vue {
  public results: SearchResult[] = [];
  public page: number = 1;
  public take: number = 6;
  public total: number = 0;
  public input!: string;
  public $route!: Route;
  public isLoading: boolean = false;
  public categories: SelectionModel[] = [];
  public selectedCategoryId: string = "";
  public hideFilters = true;
  public alsoSearchedFor: string[] = [];
  public hasError: boolean = false;
  public isActive: boolean = false;

  onSetPage(page: number) {
    this.page = page;
    this.updateResults();
  }

  public created() {
    if (this.$route.query.searchTerm) {
      this.input = this.$route.query.searchTerm.toString();
      this.updateResults();
    }
    EventBus.$on("search", (input: any) => {
      this.clearSelections();
      this.input = input;
      this.updateResults();
    });
  }

  onCategoryItemSelected(id: string) {
    this.clearSelections();
    this.selectedCategoryId = id;
    this.updateResults();
  }

  private clearSelections() {
    this.selectedCategoryId = "";
    this.page = 1;
  }

  private updateResults() {
    if (!this.input) {
      return;
    }

    this.hasError = false;
    this.isLoading = true;
    this.$http
      .get<SearchResultsModel>(
        `${config.apiAuthority}/api/search` +
          `?input=${encodeURIComponent(HtmlSanitizer.sanitize(this.input))}` +
          `&page=${this.page}` +
          `&take=${this.take}` +
          `&category=${this.selectedCategoryId}`
      )
      .then(res => {
        this.isLoading = false;
        this.results = res.data.Results;
        this.total = res.data.Total;
        if (this.selectedCategoryId == "") {
          this.categories = res.data.Categories;
          this.categories.push(<SelectionModel>{
            Title: "Show All",
            Id: "",
            Count: res.data.Total
          });
          this.isActive = false;
        }

        this.alsoSearchedFor = res.data.AlsoSearchedFor;

        window.scrollTo({ top: 0 });
      })
      .catch(e => {
        this.hasError = true;
        this.isLoading = false;
        this.results = [];
        console.log(">--------------");
        console.log(e);
        console.log("<--------------");
      });
  }
}
