
















import { Component, Prop, Vue } from "vue-property-decorator";
import { SearchResult } from "./SearchResults";
import HtmlSanitizer from "../shared/HtmlSanitizer";

const faqContentType = "Telerik.Sitefinity.DynamicTypes.Model.FrequentlyAskedQuestions.FrequentlyAskedQuestion";

@Component
export default class SearchResultsListing extends Vue {
  @Prop({ default: [] }) results!: SearchResult[];

  isFaq(result: SearchResult){
    return !!result && result.ContentType === faqContentType;
  }

  public get sanitizedResults(): SearchResult[] {
    this.results.forEach(
      r => (r.Description = HtmlSanitizer.sanitize(r.Description))
    );
    return this.results;
  }
}
