
































import {Component, Vue, Prop} from "vue-property-decorator";
import {Selection} from "./BlogPostResults";

@Component
export default class Tags extends Vue {
    @Prop({default: []}) tags!: Selection[];
    @Prop({default: ''}) selected!: string;
    public hidden: boolean = true;

    onItemClick(id: string) {
        this.$emit("select", id);
    }
}
