import Vue, { DirectiveOptions } from "vue";
import axios from 'axios';
import VueAxios from 'vue-axios';
import Router from 'vue-router';

import EventBus from './shared/EventBus';
import config from './config'
import SearchBar from "@/search/SearchBar.vue";
import SearchResultsApp from "@/search/SearchResultsApp.vue";
import FAQListingApp from "@/faq/FAQListingApp.vue";
import BlogListingApp from "@/blog/BlogListingApp.vue";
import EducationListingApp from "@/blog/EducationListingApp.vue";
import RelatedArticlesApp from "@/related-articles/RelatedArticlesApp.vue"
import RelatedArticlesWithImagesApp from "@/related-articles/RelatedArticlesWithImagesApp.vue"

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(Router);

const router = new Router({ mode: 'history', routes: [] });
// an idea for DI: https://blog.kloud.com.au/2017/03/22/dependency-injection-in-vuejs-app-with-typescript/

renderIfPresent("searchResultsApp", SearchResultsApp);
renderIfPresent("FAQListingApp", FAQListingApp);
renderIfPresent("searchBar", SearchBar);
renderIfPresent("blogListingApp", BlogListingApp);
renderIfPresent("educationListingApp", EducationListingApp);
renderRelatedArticlesIfPresent();
renderRelatedArticlesWithImagesIfPresent();

function renderRelatedArticlesIfPresent() : Vue | null {
  var elem = document.getElementById("relatedArticles");
  if (elem && elem.getAttribute("data-category-id") && elem.getAttribute("data-current-item-id")) {
    // @ts-ignore
    const relatedArticlesModule = new Vue({
      router,
      render: h => h(RelatedArticlesApp, {
        props: {
          categoryId: elem!.getAttribute("data-category-id"),
          currentItemId: elem!.getAttribute("data-current-item-id"),
          take: elem!.getAttribute("data-take")
        }
      })
    }).$mount(elem); 
    return relatedArticlesModule;
  }
  return null;
}
function renderRelatedArticlesWithImagesIfPresent() : Vue | null {
  var elem = document.getElementById("relatedArticlesWithImages");
  if (elem && elem.getAttribute("data-category-id") && elem.getAttribute("data-current-item-id")) {
    // @ts-ignore
    const relatedArticlesModule = new Vue({
      router,
      render: h => h(RelatedArticlesWithImagesApp, {
        props: {
          categoryId: elem!.getAttribute("data-category-id"),
          currentItemId: elem!.getAttribute("data-current-item-id"),
          take: elem!.getAttribute("data-take")
        }
      })
    }).$mount(elem); 
    return relatedArticlesModule;
  }
  return null;
}
function renderIfPresent(id: string, type: any): Vue | null {
  var elem = document.getElementById(id);
  if (elem) {
    // @ts-ignore
    const module = new Vue({
      router,
      render: h => h(type)
    }).$mount(elem); //
    
    return module;
  }
  return null;
}

EventBus.$on("search", (term: any) => {
  if (isOnSearchResults()) {
    //https://developer.mozilla.org/en-US/docs/Web/API/History_API
    history.pushState({}, `Search Results: ${term}`, getResultsUrl(term));
  }
  else {    
    window.location.href = `/${getResultsUrl(term)}`;
  }

  function isOnSearchResults() {
    return window.location.pathname == `/${config.searchResultsPath}`;
  }

  function getResultsUrl(term: string) {
    return `${config.searchResultsPath}?searchTerm=${term}`;
  }
});