












import { Component, Vue, Prop } from "vue-property-decorator";
import SearchResultsModel from "../search/SearchResults";
import { SearchResult } from "../search/SearchResults";
import config from "../config";
import HtmlSanitizer from "../shared/HtmlSanitizer";

@Component
export default class RelatedArticlesApp extends Vue {
  public results: SearchResult[] = [];
  public isLoading: boolean = false;
  @Prop({ default: "" }) public categoryId!: string;
  @Prop({ default: "" }) public currentItemId!: string;
  @Prop({ default: "5" }) public take!: string;
  public hideFilters = true;

  public created() {
    this.updateResults();
  }

  updateResults() {
    this.isLoading = true;
    this.$http
      .get<SearchResultsModel>(
        `${config.apiAuthority}/api/search` +
          `?input` +
          `&page=1` +
          `&take=${this.take}` +
          `&category=${this.categoryId}`
      )
      .then(
        res => {
          this.isLoading = false;
          res.data.Results.forEach(
            (r: SearchResult) => (r.Description = HtmlSanitizer.sanitize(r.Description))
          )
          this.results = res.data.Results.filter( (r: SearchResult) => r.Id != this.currentItemId);;
        }
      );
  }
}
